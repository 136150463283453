const parseMediaRef = (mediaReference) => {
  let startIndex;
  let mediaId;
  let playerUrl;
  // check if mediaReference is already correct
  if (mediaReference.includes('player.vimeo') || mediaReference.includes('youtube.com/embed')) {
    playerUrl = mediaReference;
  } else if (mediaReference.includes('vimeo')) {
    // create vimeo player url
    startIndex = mediaReference.lastIndexOf('/');
    mediaId = mediaReference.substr(startIndex + 1, mediaReference.length - 1);
    playerUrl = `https://player.vimeo.com/video/${mediaId}`;
  } else if (mediaReference.includes('youtu.be')) {
    // create player url from youtube short link
    startIndex = mediaReference.lastIndexOf('/');
    mediaId = mediaReference.substr(startIndex + 1, mediaReference.length - 1);
    playerUrl = `https://www.youtube.com/embed/${mediaId}`;
  } else if (mediaReference.includes('youtube')) {
    // create player url from youtube link
    startIndex = mediaReference.indexOf('=');
    mediaId = mediaReference.substr(startIndex + 1, mediaReference.length - 1);
    // remove extraneous query strings
    if (mediaId.includes('&')) {
      mediaId = mediaId.slice(0, mediaId.indexOf('&'));
    }
    playerUrl = `https://www.youtube.com/embed/${mediaId}`;
  }
  return playerUrl;
};
export default parseMediaRef;
