/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import useAlertBox from '@utils/useAlertBox';
import Title from '@components/Title';
import PresentationSection from '@components/PresentationSection';
import MediaGallery from '@components/MediaGallery';
import CTABanner from '@components/CTAContentful';
import SponsorPlug from '@components/SponsorPlug';
import HeroImage from '@components/HeroImage';
import HeadSEO from '@components/Navigation/HeadSEO';
import withNullChecks from '@templates/HOC/withNullChecks';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import { getHeadProps } from '@utils/navigationHelpers';
import Breadcrumbs from '@components/Navigation/Breadcrumbs';
import SponsorLockup from '@components/SponsorLockup';
import rendererOptions from '../../../richtext-rendering/renderer-options';
import useDeviceDetect from '../../../utils/useDeviceDetect';

import './index.scss';

export const pageQuery = graphql`
  query PresentationList($slug: String!) {
    page: contentfulPresentationList(slug: { eq: $slug } ) {
      bannerHeading {
        banner {
          title
          file {
            url
          }
          gatsbyImageData(layout: FULL_WIDTH)
        }
        newTab
        titleBanner
        subtitle
        buttonText
        link
        sponsorLogos {
          url
          width
          logoImage {
            description
            gatsbyImageData
            file {
              url
            }
          }
        }
        title
      }
      sections {
        ...PresentationSectionTypes
      }
      slug
      brandColorOverride
      titleDisplay
      body {
        raw
        references {
          ...RichTextReferences
        }
      }
      sponsorText1
      sponsorLogo1 {
        url
        width
        logoImage {
          description
          gatsbyImageData
          file {
            url
          }
        }
        newTab
      }
      sponsorText2
      sponsorLogo2 {
        url
        width
        logoImage {
          description
          gatsbyImageData
          file {
            url
          }
        }
        newTab
      }
      titleSeo
      descriptionSeo
      keywordsSeo
      sponsorLockupText1
      sponsorLockupLogo1 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText2
      sponsorLockupLogo2 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText3
      sponsorLockupLogo3 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      sponsorLockupText4
      sponsorLockupLogo4 {
        url
        width
        title
        logoImage {
          description
          gatsbyImageData(height: 60, layout: FIXED)
          file {
            url
          }
        }
        newTab
      }
      actions {
        title
        heading
        copy
        buttonText
        buttonUrl
        newTab
      }
      mediaGallery {
        ...MediaGalleryTypes
      }
      additionalDetails {
        raw
        references {
          ...RichTextReferences
        }
      }
    }
    breadCrumbs: contentfulPresentationList(slug: { eq: $slug }) {
      titleBreadcrumb
      parentPage {
        ...ParentPageRecursive
      }
    }
    contentfulHomePage ( titleDisplay: { eq: "Home Page"} ) {
      alertBox {
        raw
        references {
          ...RichTextReferences
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const { page } = data;
  const {
    pageTitle, pageDescription, pageKeywords, pageImgUrl, pageAltText,
  } = getHeadProps(page);

  return (
    <HeadSEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pageKeywords={pageKeywords}
      pageImgUrl={pageImgUrl}
      pageAltText={pageAltText}
    />
  );
};

const PresentationList = ({ data }) => {
  const { page, breadCrumbs } = data;
  const sponsorList = page.sponsorLogo1
    ? page.sponsorLogo1.map((logo) => (<SponsorPlug key={uuidv4()} logo={logo} />)) : null;

  const sponsorList2 = page.sponsorLogo2
    ? page.sponsorLogo2.map((logo) => (<SponsorPlug key={uuidv4()} logo={logo} />)) : null;

  const { isMobile } = useDeviceDetect();

  const displayList = (page.sections)
    ? page.sections.map((section, index) => {
      let banner = {};
      if (section.bannerHeading) {
        banner = section.bannerHeading.banner;
      }
      if (section.banner) {
        banner = section.banner;
      }
      let pageSectionUrl = null;
      if (section.slug) {
        pageSectionUrl = `http://new.smm.org/${section.slug}`;
      }
      return (
        <PresentationSection
          brandColorOverride={page.brandColorOverride}
          key={uuidv4()}
          banner={banner}
          videoUrl={section.videoUrl}
          title={section.cardTitle || section.titleDisplay}
          text={section.cardTeaser || section.text}
          subhead={section.cardSubhead || section.subhead}
          buttonText={section.cardButtonText || section.buttonText}
          buttonUrl={pageSectionUrl || section.buttonUrl}
          textLeft={index % 2 === 1}
          sponsor={section.sponsorLogo1}
          sponsor2={section.sponsorLogo2}
          sponsorText={section.sponsorText1}
          sponsorText2={section.sponsorText2}
          newTab={section.newTab}
        />
      );
    }) : null;

  const { alertBoxData, hasAlert } = useAlertBox(data);

  const getContainerClassNames = () => {
    if (page.additionalDetails) {
      if (isMobile) {
        return null;
      }
      return 'upperBody';
    }
    return 'upper-body-no-additional-details container';
  };

  const getBodyClassNames = () => {
    if (page.additionalDetails) {
      if (isMobile) {
        return 'upper-body-no-additional-details container';
      }
      return 'body-text';
    }
    return null;
  };

  return (
    <StandardPageWrapper alertBoxData={alertBoxData} hasAlert={hasAlert}>
      <Breadcrumbs parentData={breadCrumbs} />
      {page.bannerHeading && (
        <HeroImage
          buttonText={page.bannerHeading.buttonText}
          image={page.bannerHeading.banner}
          link={page.bannerHeading.link}
          subhead={page.bannerHeading.subtitle}
          title={page.bannerHeading.titleBanner}
          isTitleUnderlined
          logos={page.bannerHeading.sponsorLogos}
          newTab={page.bannerHeading.newTab}
        />
      )}
      <div>
        <Row className="no-gutters">
          <Col sm={12}>
            {!page.bannerHeading && (
              <Title title={page.titleDisplay} titleSize="h2" classes="text-center mt-1 text-underline text-underline-color-primary" />
            )}
            <div className={getContainerClassNames()}>
              <div className={getBodyClassNames()}>
                {page.body
                  && renderRichText(page.body, rendererOptions)}
              </div>
              {!isMobile ? (
                <div className={page.additionalDetails ? 'additional-details' : null}>
                  {page.additionalDetails
                    && renderRichText(page.additionalDetails, rendererOptions)}
                </div>
              ) : null}
              {!isMobile
                && (
                  <div className="float-logos">
                    <div className={page.additionalDetails ? (!isMobile ? 'sponsorText1' : null) : null}>
                      {page.sponsorText1}
                      {' '}
                      {sponsorList}
                    </div>
                    <div className={page.additionalDetails ? (isMobile ? 'sponsorText2-mobile' : 'sponsorText2') : 'sponsorText2-no-additional-details'}>
                      {page.sponsorText2}
                      {' '}
                      {sponsorList2}
                    </div>
                  </div>)}
              <div className="clearfix" />
              {isMobile ? (
                <div className={page.additionalDetails ? 'additional-details-mobile' : null}>
                  {page.additionalDetails
                    && renderRichText(page.additionalDetails, rendererOptions)}
                </div>
              ) : null}
              {isMobile ? (
                <div>
                  <div className={page.additionalDetails ? null : null}>
                    <div className="float-text-mobile">
                      {page.sponsorText1}
                    </div>
                    <div className="float-logos-mobile">
                      {sponsorList}
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className={page.additionalDetails ? null : 'sponsorText2-no-additional-details'}>
                    <div className="float-text-mobile">
                      {page.sponsorText2}
                    </div>
                    <div className="float-logos-mobile">
                      {sponsorList2}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {displayList}
          </Col>
        </Row>
        {(page.mediaGallery && <MediaGallery page={page} />)}
        {(page.sponsorLockupText1
          || page.sponsorLockupText2
          || page.sponsorLockupText3
          || page.sponsorLockupText4
          || page.sponsorLockupLogo1
          || page.sponsorLockupLogo2
          || page.sponsorLockupLogo3
          || page.sponsorLockupLogo4)
          && <SponsorLockup page={page} />}
      </div>
      {page.actions && <CTABanner
        actions={page.actions}
        brandColorOverride={page.brandColorOverride}
      />}
    </StandardPageWrapper>
  );
};

PresentationList.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default withNullChecks({ dataKey: 'page', typeName: 'presentationList' })(PresentationList);
