// TODO: Fix this eslint warning and remove whitelisting
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { v4 as uuidv4 } from 'uuid';
import Link from '@components/Link';
import SponsorPlug from '@components/SponsorPlug';
import Title from '@components/Title';
import parseMediaRef from '../../utils/parseMediaRef';
import rendererOptions from '../../richtext-rendering/renderer-options';

import './index.scss';

const PresentationSection = (props) => {
  const {
    banner, text, title, subhead, buttonText, brandColorOverride,
    buttonUrl, textLeft, sponsor, sponsor2, sponsorText, sponsorText2, videoUrl, newTab,
  } = props;

  const sponsorList = sponsor
    ? sponsor.map((logo) => (
      <SponsorPlug key={uuidv4()} logo={logo} logoWidth="125px" supportText={false} />
    )) : null;
  const sponsorList2 = sponsor2
    ? sponsor2.map((logo) => (
      <SponsorPlug key={uuidv4()} logo={logo} logoWidth="125px" supportText={false} />
    )) : null;

  const textColLeft = (
    <Col md={4} className="column">
      <div className="text-container left">
        <Title title={title} titleSize="h2" classes="heading-bold" />
        <hr className="hr-accent" style={{ borderColor: brandColorOverride }} />
        <div className="subhead">{subhead}</div>
        {sponsorList && (
          <div className="sponsor">
            {sponsorText}
            {sponsorList}
          </div>
        )}
        {sponsorList2 && (
          <div className="sponsor">
            {sponsorText2}
            {sponsorList2}
          </div>
        )}
        <div className="text">
          {(text === null || typeof text === 'string') ? text : renderRichText(text, rendererOptions)}
        </div>
        {buttonUrl && (
          <Link to={buttonUrl} newTab={newTab} className={brandColorOverride ? 'custom-brand' : ''}>
            <Button
              className="btn-primary"
              style={{ backgroundColor: brandColorOverride, borderColor: brandColorOverride }}
            >
              {buttonText}
            </Button>
          </Link>
        )}
      </div>
    </Col>
  );
  const textColRight = (
    <Col md={4} className="column">
      <div className="text-container right">
        <Title title={title} titleSize="h2" classes="heading-bold" />
        <hr className="hr-accent" style={{ borderColor: brandColorOverride }} />
        <div className="subhead">{subhead}</div>
        {sponsorList && (
          <div className="sponsor">
            {sponsorText}
            {sponsorList}
          </div>
        )}
        {sponsorList2 && (
          <div className="sponsor">
            {sponsorText2}
            {sponsorList2}
          </div>
        )}
        <div className="text">
          {(text === null || typeof text === 'string') ? text : renderRichText(text, rendererOptions)}
        </div>
        {buttonUrl && (
          <Link to={buttonUrl} newTab={newTab} className={brandColorOverride ? 'custom-brand' : ''}>
            <Button
              className="btn-primary"
              style={{ backgroundColor: brandColorOverride, borderColor: brandColorOverride }}
            >
              {buttonText}
            </Button>
          </Link>
        )}
      </div>
    </Col>
  );
  const mediaCol = (
    <Col md={8} className="media-col">
      { banner && !videoUrl && (
        <GatsbyImage
          alt={banner.description}
          data-cy="gatsby-img"
          image={getImage(banner)}
        />
      )}
      {videoUrl && (
        <div className="iframe-container">
          <iframe
            title={`embedded video at ${videoUrl}`}
            src={parseMediaRef(videoUrl)}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      )}
    </Col>
  );

  if (textLeft) {
    return (
      <Row className="left">
        {textColLeft}
        {mediaCol}
      </Row>
    );
  }
  return (
    <Row className="right">
      {mediaCol}
      {textColRight}
    </Row>
  );
};

PresentationSection.defaultProps = {
  text: null,
  title: '',
  subhead: null,
  buttonText: null,
  buttonUrl: null,
  textLeft: true,
  sponsor: null,
  sponsor2: null,
  sponsorText: null,
  sponsorText2: null,
  videoUrl: null,
  newTab: null,
  brandColorOverride: null,
};

PresentationSection.propTypes = {
  brandColorOverride: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  subhead: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  banner: PropTypes.objectOf(PropTypes.any).isRequired,
  textLeft: PropTypes.bool,
  sponsor: PropTypes.arrayOf(PropTypes.any),
  sponsor2: PropTypes.arrayOf(PropTypes.any),
  sponsorText: PropTypes.string,
  sponsorText2: PropTypes.string,
  videoUrl: PropTypes.string,
  newTab: PropTypes.bool,
};

export default PresentationSection;
